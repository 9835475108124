/// Import all fonts and typography styling
@import './fonts';
/// Import all utility classes
@import './util';

@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

.text-line {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: dash 4s linear forwards, filling 3s ease-in forwards;
}

.text-line text {
  font-family: Helvetica;
  font-weight: normal;
  font-style: normal;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  0%, 90% { fill: black; fill-opacity: 0; }
  100% { fill: black; fill-opacity: 1; }
}

#particles-js .tsparticles-canvas-el {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-animation: appear 2.4s 1;
  animation: appear 2.4s 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes appear{
  from {
    @include transform(scale(0));
    opacity:0;
  }
  to {
    @include transform(scale(1));
    opacity:1;
  }
}